import React, { useEffect, useState } from "react";
import * as S from "./ ConfimarCanditaduraStyled";
import { Logo } from "../../components";
import { apiBancoDeTalentos } from "../../services/base.service";
import Swal from "sweetalert2";

const ComfirmarCanditadura = (props) => {
  const [vaga, setVaga] = useState({});
  useEffect(() => {
    obterVaga();
    // console.log(statusLogin);
  }, []);
  useEffect(() => {
    console.log(vaga);
    /*   */
    // obterVaga();
    // console.log(statusLogin);
  }, [vaga]);

  const obterVaga = () => {
    apiBancoDeTalentos
      .get(`vagas/${props.match.params.vagaId}`)
      .then((result) => {
        console.log(result.data.data);
        setVaga(result.data.data);
        // setTimeout(() => {
        console.log(vaga);
        // }, 2000);
      });
  };

  const candidatarVaga = () => {
    Swal.fire({
      title: "Tem certeza de que deseja se candidatar a esta vaga?",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      reverseButtons: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire({
          title: "Enviando candidatura...",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        apiBancoDeTalentos
          .get(
            `vagas/cadidatura?VagaId=${props.match.params.vagaId}&UsuarioId=${props.match.params.usuarioId}`
          )
          .then((result) => {
            Swal.fire({
              title: "Candidatura enviada com sucesso!",
              text: "",
              icon: "success",
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
            });
          })
          .catch((error) => {
            console.log(error);
            const errorMessage =
              error.response?.data?.errors?.[0] || "Erro ao enviar candidatura";
            // Fecha o alerta de carregamento e exibe uma mensagem de erro, se necessário
            Swal.fire({
              title: errorMessage,
              text: "Por favor, tente novamente.",
              icon: "error",
              showConfirmButton: true,
            });
          });

        // Swal.fire("Saved!", "", "success");
      } else if (result.isDenied) {
        // Swal.fire({
        //   title: "Candidatura enviada com sucesso!",
        //   text: "",
        //   icon: "success",
        //   showConfirmButton: false,
        //   allowOutsideClick: false,
        //   allowEscapeKey: false,
        //   allowEnterKey: false,
        // });
      }
    });
  };
  return (
    <>
      {/* <S.Titulo>Resumo da inscrição</S.Titulo> */}

      <S.Container>
        <S.BoxLogo>
          <Logo width={200} marginBottom={20} marginTop={30} />
        </S.BoxLogo>
        <S.InfoCanditadura>
          <S.BoxInfo>
            <span style={{ marginBottom: "20px" }}>
              <strong>Descrição: </strong>{" "}
              {vaga.descricao ? vaga.descricao : "-"}
            </span>

            <span style={{ marginBottom: "20px" }}>
              <strong>Requisito: </strong>{" "}
              {vaga.preResquisito ? vaga.preResquisito : "-"}
            </span>

            <span style={{ marginBottom: "20px" }}>
              <strong>Setor: </strong> {vaga.setor ? vaga.setor : "-"}
            </span>

            <span style={{ marginBottom: "20px" }}>
              <strong>PCD: </strong> {vaga.pcd ? "Sim" : "Não"}
            </span>
            <span style={{ marginBottom: "20px" }}>
              <strong>Unidade: </strong> {vaga.unidade ? vaga.unidade : "-"}
            </span>
          </S.BoxInfo>
          <S.BoxButton>
            <S.GifJoinha src="https://condominioappstorage.blob.core.windows.net/conectedu/git-joinha.gif" />
            <S.ButtonCandidatar onClick={candidatarVaga}>
              Desejo me Candidatar a Vaga
            </S.ButtonCandidatar>
          </S.BoxButton>
        </S.InfoCanditadura>
      </S.Container>
    </>
  );
};

export default ComfirmarCanditadura;
