import styled from "styled-components";

const Container = styled.div`
  height: 100vh;
  background: #096875;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const InfoCanditadura = styled.div`
  width: 70%;
  max-width: 800px;
`;
const BoxInfo = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  margin-bottom: 20px;
  padding: 15px;
`;

const BoxButton = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  padding: 15px;
`;

const BoxLogo = styled.div``;
const GifJoinha = styled.img`
  width: 80px;
  margin-right: 20px;
  @media (max-width: 720px) {
    display: none;
  }
`;

const ButtonCandidatar = styled.button`
  background-color: rgb(48, 133, 214);
  /* margin-top: 10px; */
  border-radius: 4px;
  border: none;
  width: 100%;
  height: 50px;
  padding: 10px;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
`;

export {
  Container,
  InfoCanditadura,
  BoxInfo,
  BoxButton,
  BoxLogo,
  GifJoinha,
  ButtonCandidatar,
};
