import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_IDENTIDADE,
  auth: false,
});

const apiUser = axios.create({
  baseURL: process.env.REACT_APP_API_HUB_V2,
  auth: false,
});

const apiBancoDeTalentos = axios.create({
  baseURL: "https://degaserj.conectedu.com/api/v2/banco-talentos/",
  auth: false,
});

export { api, apiUser, apiBancoDeTalentos };
